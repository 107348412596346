import React from "react";
import Helmet from "react-helmet";
import BlogRoll from "../BlogRoll";
import BlogHeader from "../BlogHeader";
import { HTMLContent } from "../Content";

import { get } from "lodash";

import { blogCategory } from "../../lib/gtm";

import styles from "./styles.module.less";

import { getUuidFromUrl, resizeTo } from "../../lib/ucImage";
import { corporateAuthor } from "../../lib/utility";

const BlogDetail = ({
    title,
    tags = [],
    categories = [],
    bloghero = "",
    image = "",
    photoAlignment = "center",
    date,
    html,
    author,
    blogs,
    id,
    preview = false,
}) => {
    let d = date ? new Date(date.toString()) : new Date();
    let blogDate = d.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
    let { recommendedBlogs = [], exactBlogs = [] } = blogs;
    // tags and categories may come back as null from GraphQL API
    tags = tags || [];
    categories = categories || [];
    // pick out blogs with exactly the same categories and tags
    //dont show same blog in recommended
    for (let i = 0; i < categories.length; i++) {
        exactBlogs = exactBlogs.filter(blog => {
            return blog.categories.includes(categories[i]) && !(blog.id === id);
        });
    }
    for (let i = 0; i < tags.length; i++) {
        exactBlogs = exactBlogs.filter(blog => {
            return blog.tags.includes(tags[i]) && !(blog.id === id);
        });
    }
    // remove same blog in recomended
    recommendedBlogs = recommendedBlogs.filter(blog => {
        return !(blog.id === id);
    });
    let authorImage, authorName, authorBranch, authorLink, authorSlug;
    authorSlug = get(author, "slug");
    authorImage = get(author, "gallery.photo", "");
    authorName = get(author, "profile.name", "");
    authorLink =
        authorSlug === corporateAuthor.slug
            ? corporateAuthor.slug
            : `/bay-equity-news${get(author, "slug", "")}`;
    authorBranch = get(author, "profile.branch.title", "");

    let featuredImage = bloghero || image;
    let socialImage = !!featuredImage
        ? resizeTo(getUuidFromUrl(featuredImage), 1200, 627)
        : null;
    let heroImage = !!featuredImage
        ? resizeTo(getUuidFromUrl(featuredImage), 1920 * 1.5, 1080 * 1.5)
        : null;

    return (
        <section data-background="light">
            <div className={styles.container}>
                {!preview && <BlogHeader categories={categories} />}
                <div className={styles.date}>{blogDate}</div>
                <h1>{title}</h1>
                <div className={styles.name}>
                    {authorImage && (
                        <a href={authorLink}>
                            <img
                                data-blink-src={authorImage}
                                alt={authorName}
                            />
                        </a>
                    )}
                    <div className={styles.copy}>
                        <a href={authorLink}>
                            {!!authorName && `${authorName} | ${authorBranch}`}
                        </a>
                    </div>
                </div>
                {!!featuredImage && (
                    <>
                        <div
                            style={{
                                backgroundImage: `url('${heroImage}')`,
                                backgroundPosition: photoAlignment || "center",
                            }}
                            className={styles.featureImg}
                        />
                        <Helmet>
                            <meta property="og:image" content={socialImage} />
                            <meta name="x:image" content={socialImage} />
                            <meta itemProp="image" content={socialImage} />
                        </Helmet>
                    </>
                )}
                <HTMLContent content={html} className={styles.html} />
            </div>
            {!preview && <BlogCategories tags={tags} categories={categories} />}
            <div className={styles.recommended}>
                <BlogRoll
                    key="blog"
                    blogs={exactBlogs}
                    corporateBlogs={recommendedBlogs}
                    showBlogs={true}
                    word="word"
                    title="Recommended Blogs"
                    bg=""
                />
            </div>
        </section>
    );
};

export default BlogDetail;

const BlogCategories = ({ categories = [], tags = [] }) => {
    return (
        <div className={styles.categories}>
            {categories.map((s, i) => {
                return (
                    <div className={styles.category} key={i}>
                        <a
                            href={`/bay-equity-news/${s
                                .trim()
                                .replace(/ /g, "-")
                                .toLowerCase()}`}
                            data-gtm={blogCategory}>
                            {s}
                        </a>
                    </div>
                );
            })}
            {tags.map((s, i) => {
                return (
                    <div className={styles.category} key={i + 1}>
                        <a
                            href={`/bay-equity-news/${s
                                .trim()
                                .replace(/ /g, "-")
                                .toLowerCase()}`}
                            data-gtm={blogCategory}>
                            {s}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};
