import React, { Component } from "react";

import { blogCategory } from "../../lib/gtm";

import styles from "./styles.module.less";

const allcategories = [
    "All About BE",
    "First-Time Homebuyer",
    "Current Homeowner",
    "DIY Projects",
    "Resources",
    "Beyond BE",
];

class BlogHeader extends Component {
    static defaultProps = {
        categories: [],
    };

    componentDidMount() {
        const { categories } = this.props;
        const first = categories[0];
        if (!first) return;
        const el = document.getElementById(first);
        if (!!el) {
            el.scrollIntoView({ inline: "center" });
        }
    }

    render() {
        const categories = this.props.categories;
        return (
            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    {allcategories.map((c, i) => {
                        return (
                            <div
                                className={
                                    Array.isArray(categories) &&
                                    categories.includes(c)
                                        ? styles.headerCategoryActive
                                        : styles.headerCategory
                                }
                                key={i}
                                id={c}>
                                <a
                                    href={`/bay-equity-news/${c
                                        .trim()
                                        .replace(/ /g, "-")
                                        .toLowerCase()}`}
                                    data-gtm={blogCategory}>
                                    {c.toUpperCase()}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default BlogHeader;
