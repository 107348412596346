import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import Header from "../components/Header";
import BlogDetail from "../components/BlogDetail";
import { mapBlogs, corporateAuthor } from "../lib/utility";

import { graphql } from "gatsby";

const Blog = ({ data }) => {
    const { blog, relatedBlogs, exactBlogs, recentBlogs, siteMeta } = data;
    const { id, slug, title, author, html } = blog;
    const {
        siteMetadata: { siteUrl },
    } = siteMeta;
    const canonical = `${siteUrl}${slug}`;
    if (author === null) {
        blog.author = Object.assign({}, corporateAuthor);
    }
    const { edges: rBlogs = [] } = recentBlogs || {};
    const { edges: reBlogs = [] } = relatedBlogs || {};
    const { edges: eBlogs = [] } = exactBlogs || {};
    let blogProp = {
        recommendedBlogs: mapBlogs(reBlogs).concat(mapBlogs(rBlogs)),
        exactBlogs: mapBlogs(eBlogs),
    };
    const header = (
        <Header
            active={["tools-and-resources", "bay-equity-news"]}
            theme="blue-ribbon"
        />
    );

    const metaDescription = blog.metadata || `${title}. Learn More.`;

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>{title} | Bay Equity Home Loans</title>
                <meta name="description" content={metaDescription} />
                <meta
                    property="og:title"
                    content={`${title} | Bay Equity Home Loans`}
                />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
                <meta
                    name="twitter:title"
                    content={`${title} | Bay Equity Home Loans`}
                />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <BlogDetail {...blog} html={html} blogs={blogProp} id={id} />
        </Layout>
    );
};

export default Blog;

export const pageQuery = graphql`
    query blogPostById(
        $id: String!
        $categories: [String!]!
        $tags: [String!]!
    ) {
        blog(id: { eq: $id }) {
            id
            html
            slug
            metadata
            title
            categories
            tags
            date
            bloghero
            image
            author {
                slug
                profile {
                    name
                    branch {
                        slug
                        title
                    }
                }
                gallery {
                    photo
                }
            }
        }
        exactBlogs: allBlog(
            limit: 3
            sort: { fields: [date], order: DESC }
            filter: { categories: { in: $categories }, tags: { in: $tags } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
        relatedBlogs: allBlog(
            limit: 4
            sort: { fields: [date], order: DESC }
            filter: { categories: { in: $categories }, tags: { in: $tags } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
        recentBlogs: allBlog(limit: 4, sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
        siteMeta: site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;
